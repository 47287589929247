import { useTranslation } from "react-i18next";
import { Link } from "react-router-dom";
import "../../i18n";
import { getDashActions, encodeCSV } from "@/utils/admin/generic";
import { useEffect } from "react";

import { validateToken, getBundleExport } from "@/utils/admin/fetch";
import { handleLogout } from "@/utils/admin/logout";
import Status from "../../components/admin/Status";
import * as XLSX  from 'xlsx'

const Home = () => {
  const { t } = useTranslation("translation");

  useEffect(() => {
    validateToken().then((res) => {
      if (!res) handleLogout();
    });
  }, []);

  const handleBundleExport = () => {
    var date = new Date();
    var timestamp = date.getFullYear() + "-" + (date.getMonth() + 1) + "-" + date.getDate() + "-" +  date.getHours() + "-" + date.getMinutes();
    getBundleExport(timestamp)
    .then(res=>res.json())
    .then(data => {
      const ws = XLSX.utils.json_to_sheet(data);
      const wb = XLSX.utils.book_new();
      XLSX.utils.book_append_sheet(wb, ws, 'Export');
      XLSX.writeFile(wb, `Bundles-${timestamp}.xlsx`);
    })
    .catch(e => console.log(e))
  }

  return (
    <div className="page-content">
      <div className="container mx-auto py-12">
        <h3 className="text-2xl font-medium leading-6 text-gray-900 mb-8">
          {t('home.action')}
        </h3>
        <div className="divide-y divide-gray-200 overflow-hidden bg-gray-200 shadow sm:grid sm:grid-cols-2 sm:gap-px sm:divide-y-0">
          {getDashActions(t).map((action) => (
            <div
              key={action.title}
              className={
                "relative group bg-white p-6 focus-within:ring-2 focus-within:ring-inset focus-within:ring-black"
              }
            >
              <div className="mt-8">
                <h3 className="text-lg font-medium">
                  <Link to={action.href} className="focus:outline-none">
                    {/* Extend touch target to entire panel */}
                    <span className="absolute inset-0" aria-hidden="true" />
                    {action.title}
                  </Link>
                </h3>
                <p className="mt-2 text-sm text-gray-500">
                  {action.description}
                </p>
              </div>
              <span
                className="pointer-events-none absolute top-6 right-6 text-gray-300 group-hover:text-gray-400"
                aria-hidden="true"
              >
                <svg
                  className="h-6 w-6"
                  xmlns="http://www.w3.org/2000/svg"
                  fill="currentColor"
                  viewBox="0 0 24 24"
                >
                  <path d="M20 4h1a1 1 0 00-1-1v1zm-1 12a1 1 0 102 0h-2zM8 3a1 1 0 000 2V3zM3.293 19.293a1 1 0 101.414 1.414l-1.414-1.414zM19 4v12h2V4h-2zm1-1H8v2h12V3zm-.707.293l-16 16 1.414 1.414 16-16-1.414-1.414z" />
                </svg>
              </span>
            </div>
          ))}
        </div>
        <Status />
        <div className="container mx-auto pb-12">
          <h3 className="text-lg font-medium leading-6 text-gray-900 mb-5">
            {t('bundle-actions.title')}
          </h3>
          <button onClick={handleBundleExport} className="mt-1.5 sm:mt-0 btn px-3.5 py-2.5 bg-white font-semibold text-sm bg-gray-100 hover:opacity-50 duration-200">
            {t("bundle-actions.export")}
          </button>
        </div>
      </div>
    </div>
  );
};

export default Home;
