import { LOCALES, ACTIVITIES } from "@/const"

export const validateInputs = (inputs) => {
    if(!inputs.locales?.length || inputs.locales.some(locale => !LOCALES.includes(locale))) return false

    if(inputs.bundleActivity){
        for(const activity of inputs.bundleActivity?.split(';')){
            if (!ACTIVITIES.includes(activity)) return false
        }
    }

    if (!inputs.bundleProduct1SKU || inputs.bundleProduct1SKU?.length !== 4) return false
    if (!inputs.bundleProduct2SKU || inputs.bundleProduct2SKU?.length !== 4) return false

    if (inputs.bundleProduct1Variant && inputs.bundleProduct1Variant.length !== 3) return false
    if (inputs.bundleProduct2Variant && inputs.bundleProduct2Variant.length !== 3) return false

    if (inputs.bundleProduct3SKU && inputs.bundleProduct3SKU.length !== 4) return false
    if (inputs.bundleProduct3Variant && inputs.bundleProduct3Variant.length !== 3) return false

    if(!inputs.bundleProduct1Image1Type || inputs.bundleProduct1Image1Type.length > 5) return false
    if(!inputs.bundleProduct1Image2Type || inputs.bundleProduct1Image2Type.length > 5) return false
    if(!inputs.bundleProduct2Image1Type || inputs.bundleProduct2Image1Type.length > 5) return false
    if(!inputs.bundleProduct2Image2Type || inputs.bundleProduct2Image2Type.length > 5) return false
    
    if(inputs.bundleProduct3SKU && (!inputs.bundleProduct3Image1Type || inputs.bundleProduct3Image1Type.length > 5)) return false
    if(inputs.bundleProduct3SKU && (!inputs.bundleProduct3Image2Type || inputs.bundleProduct3Image2Type.length > 5)) return false

    return true
}


export const serializeProducts = inputs => {
    let products = {}
    for (const key in inputs) {
        if (key.startsWith('bundleProduct')) {
            const index = key.replace('bundleProduct', '')[0]
            const property = key.replace('bundleProduct', '').slice(1).toLowerCase()
            if (!products[index]) products[index] = {}
            products[index][property] = inputs[key]
        }
    }
    return Object.keys(products).map(key => products[key])
}

export const serializeInputs = async (products,data) => {
    const result = {}
    await products.forEach((product, index) => {
        result[`bundleProduct${index + 1}SKU`] = product.sku
        result[`bundleProduct${index + 1}Variant`] = product.variant
        result[`bundleProduct${index + 1}Fallback`] = product.fallback
        result[`bundleProduct${index + 1}Image1Type`] = data.plp_images.first[product.sku]
        result[`bundleProduct${index + 1}Image2Type`] = data.plp_images.second[product.sku]
    })
    return result
}

export const getUpdatedAtTimeString = (bundleUpdatedAt) => {
    const updatedAt = new Date(bundleUpdatedAt)
    return `${updatedAt.toLocaleDateString()} ${updatedAt.toLocaleTimeString()}`
}