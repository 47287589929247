const HREFS = {
    newBundle: '/bundles/new',
    listPerLocale: '/list',
    listPerId: '/list/cross-locale',
    import: '/bundles/import'
}

export const getDashActions = (t) => [
    {
        title: t("home.add-multi-locale-bundle"),
        description: t("home.add-multi-locale-bundle-description"),
        href: HREFS.newBundle
    },
    {
        title: t("home.view-bundles-per-locale"),
        description: t("home.view-bundles-per-locale-description"),
        href: HREFS.listPerLocale
    },
    {
        title: t("home.view-all-multi-locale-bundle"),
        description: t("home.view-all-multi-locale-bundle-description"),
        href: HREFS.listPerId
    },
    {
        title: t("home.import-file"),
        description: t("home.import-file-description"),
        href: HREFS.import
    },
]

export const getDrawerActions = (t) => [
    {
        title: t("drawer.add-multi-locale-bundle"),
        href: HREFS.newBundle
    },
    {
        title: t("drawer.import-file"),
        href: HREFS.import
    },
    {
        title: t("drawer.view-all-multi-locale-bundle"),
        href: HREFS.listPerId
    },
    {
        title: t("drawer.view-bundles-per-locale"),
        href: HREFS.listPerLocale
    }
]

export const encodeCSV = data => data.reduce((csv,row) => csv += row.join(';') + '\n', []);
