import { serializeInputs, serializeProducts } from "./form";

export const getBundleList = async (params = {}) => {
    let query = Object.keys(params).map(key => key + '=' + params[key]).join('&');
    let res = await fetch(`${process.env.REACT_APP_API_URL}/admin/bundles?${query}`, {
        headers: {
            'access-token': localStorage.getItem('accessToken').replace(/['"]+/g, '')
        }
    })
    let data = await res.json()
    data.status = res.status
    return data?.bundles ? data.bundles : data
}

export const getCrossLocaleBundleList = async (params = {}) => {
    let query = Object.keys(params).map(key => key + '=' + params[key]).join('&');
    let res = await fetch(`${process.env.REACT_APP_API_URL}/admin/bundles/cross-locale?${query}`, {
        headers: {
            'access-token': localStorage.getItem('accessToken').replace(/['"]+/g, '')
        }
    })
    let data = await res.json()
    data.status = res.status
    return data?.bundles ? data.bundles : data
}

export const createNewBundle = async (inputs) => {
    let res = await fetch(`${process.env.REACT_APP_API_URL}/admin/bundles/new`, {
        method: 'POST',
        headers: {
            'Content-Type': 'application/json',
            'access-token': localStorage.getItem('accessToken').replace(/['"]+/g, '')
        },
        body: JSON.stringify({
            view_id: inputs.locales.join(';'),
            // name: inputs.bundleName,
            name: inputs.bundleId,
            // description: inputs.bundleDescription,
            image: inputs.bundleImage,
            activity: inputs.bundleActivity,
            accessories_bundle: inputs.bundleAccessories,
            gender: inputs.bundleGender,
            products: serializeProducts(inputs),
            // price: inputs.bundlePrice,
            discount: inputs.bundleDiscount,
            start_at: inputs.bundleStartDate,
            end_at: inputs.bundleEndDate,
            published: inputs.bundlePublished,
            translations: Object.keys(inputs).reduce((acc,key) => {
                if(key.includes('bundleName-')){
                    acc[key.replace("bundleName-","")] = inputs[key]
                }
                return acc
            },{})
        })
    })
    let data = await res.json()
    data.status = res.status
    return data
}


export const importCrossBundlesFromFile = async (data) => {
    let res = await fetch(`${process.env.REACT_APP_API_URL}/admin/bundles/import`, {
        method: 'POST',
        headers: {
            'access-token': localStorage.getItem('accessToken').replace(/['"]+/g, '')
        },
        body: data,
    })
    let resData = await res.json()
    resData.status = res.status
    return resData 
}


export const getBundleInputs = async (id, view_id) => {
    let data = await getBundleList({ id, view_id })
    return !data.status ? (Object.keys(data).length ? {
        locales: [data[0].view_id],
        bundleId: data[0].generic_name,
        bundleName: data[0].name,
        bundleDescription: data[0].description,
        bundleImage: data[0].image,
        bundleActivity: data[0].activity,
        bundleAccessories: data[0].accessories_bundle,
        bundleGender: data[0].gender,
        bundlePrice: data[0].price,
        bundleDiscount: data[0].discount,
        bundleStartDate: new Date(data[0].start_at).toISOString().split('T')[0],
        bundleEndDate: new Date(data[0].end_at).toISOString().split('T')[0],
        bundleUpdatedAt: data[0].updated_at,
        bundleUpdatedBy: data[0].updated_by,
        bundlePublished: data[0].published,
        ...(await serializeInputs(JSON.parse(data[0].products), JSON.parse(data[0].data)))
    } : null) : data
}

export const getCrossLocaleBundleInputs = async (id) => {
    let data = await getCrossLocaleBundleList({ id })
    return !data.status ? (Object.keys(data).length ? {
        locales: data[0].view_id.split(';'),
        bundleId: data[0].name,
        bundleDescription: data[0].description,
        bundleImage: data[0].image,
        bundleActivity: data[0].activity,
        bundleAccessories: data[0].accessories_bundle,
        bundleGender: data[0].gender,
        bundlePrice: data[0].price,
        bundleDiscount: data[0].discount,
        bundleStartDate: new Date(data[0].start_at).toISOString().split('T')[0],
        bundleEndDate: new Date(data[0].end_at).toISOString().split('T')[0],
        bundleUpdatedAt: data[0].updated_at,
        bundleUpdatedBy: data[0].updated_by,
        bundlePublished: data[0].published,
        ...(await serializeInputs(JSON.parse(data[0].products), JSON.parse(data[0].data))),
        ...(Object.keys(data[0]?.translations).reduce((acc,view_id) =>{
            acc['bundleName-' + view_id] = data[0].translations[view_id]
            return acc
        },{})),
    } : null) : data
}

export const updateBundle = async (inputs) => {
    serializeProducts(inputs)
    let res = await fetch(`${process.env.REACT_APP_API_URL}/admin/bundles/edit`, {
        method: 'PUT',
        headers: {
            'Content-Type': 'application/json',
            'access-token': localStorage.getItem('accessToken').replace(/['"]+/g, '')
        },
        body: JSON.stringify({
            id: inputs.id,
            view_id: inputs.view_id,
            name: inputs.bundleName,
            description: inputs.bundleDescription,
            image: inputs.bundleImage,
            activity: inputs.bundleActivity,
            accessories_bundle: inputs.bundleAccessories,
            gender: inputs.bundleGender,
            products: serializeProducts(inputs),
            // price: inputs.bundlePrice,
            discount: inputs.bundleDiscount,
            start_at: inputs.bundleStartDate,
            end_at: inputs.bundleEndDate,
            published: inputs.bundlePublished
        })
    })
    let data = await res.json()
    data.status = res.status
    return data
}

export const updateCrossLocaleBundle = async (inputs) => {
    serializeProducts(inputs)
    let res = await fetch(`${process.env.REACT_APP_API_URL}/admin/bundles/cross-locale/edit`, {
        method: 'PUT',
        headers: {
            'Content-Type': 'application/json',
            'access-token': localStorage.getItem('accessToken').replace(/['"]+/g, '')
        },
        body: JSON.stringify({
            id: inputs.id,
            view_id: inputs.locales.join(';'),
            name: inputs.bundleId,
            description: inputs.bundleDescription,
            image: inputs.bundleImage,
            activity: inputs.bundleActivity,
            accessories_bundle: inputs.bundleAccessories,
            gender: inputs.bundleGender,
            products: serializeProducts(inputs),
            discount: inputs.bundleDiscount,
            start_at: inputs.bundleStartDate,
            end_at: inputs.bundleEndDate,
            published: inputs.bundlePublished,
            translations: Object.keys(inputs).reduce((acc,key) => {
                if(key.includes('bundleName-')){
                    acc[key.replace("bundleName-","")] = inputs[key]
                }
                return acc
            },{})
        })
    })
    let data = await res.json()
    data.status = res.status
    return data
}

export const deleteBundle = async (id, view_id) => {
    let res = await fetch(`${process.env.REACT_APP_API_URL}/admin/bundles/delete`, {
        method: 'DELETE',
        headers: {
            'Content-Type': 'application/json',
            'access-token': localStorage.getItem('accessToken').replace(/['"]+/g, '')
        },
        body: JSON.stringify({ id, view_id })
    })
    let data = await res.json()
    data.status = res.status
    return data
}

export const deleteCrossLocaleBundle = async (id) => {
    let res = await fetch(`${process.env.REACT_APP_API_URL}/admin/bundles/cross-locale/delete`, {
        method: 'DELETE',
        headers: {
            'Content-Type': 'application/json',
            'access-token': localStorage.getItem('accessToken').replace(/['"]+/g, '')
        },
        body: JSON.stringify({ id })
    })
    let data = await res.json()
    data.status = res.status
    return data
}

export const updateBundlesOrder = async (bundles) => {
    let res = await fetch(`${process.env.REACT_APP_API_URL}/admin/bundles/cross-locale/order`, {
        method: 'POST',
        headers: {
            'Content-Type': 'application/json',
            'access-token': localStorage.getItem('accessToken').replace(/['"]+/g, '')
        },
        body:  JSON.stringify({bundles})
    })
    let data = await res.json()
    data.status = res.status
    return data
}

export const loginUser = async (credentials) => {
    return fetch(`${process.env.REACT_APP_API_URL}/admin/bundles/login`, {
        method: 'POST',
        headers: {
            'Content-Type': 'application/json'
        },
        body: JSON.stringify(credentials)
    })
        .then(data => data.json())
}

export const validateToken = async () => {
    const token = localStorage.getItem('accessToken').replace(/['"]+/g, '')
    if(!token) return false
    let res = await fetch(`${process.env.REACT_APP_API_URL}/admin/validateToken`, {
        method: 'GET',
        headers: {
            'access-token': token
        },
    })
    let data = await res.json()
    if(data.validToken) return true
    else return false
}

export const getBundleStatusData = async (params = {}) => {
    let res = await fetch(`${process.env.REACT_APP_API_URL}/admin/bundles`, {
        headers: {
            'access-token': localStorage.getItem('accessToken').replace(/['"]+/g, '')
        }
    })
    let data = await res.json()
    data.status = res.status
    return data?.bundles ? data.bundles : data
}

export const getBundleExport = async (timestamp) => {
    let res = await fetch(`${process.env.REACT_APP_API_URL}/admin/bundles/export?t=${timestamp}`, {
        headers: {
            'access-token': localStorage.getItem('accessToken').replace(/['"]+/g, '')
        }
    })
    return res
}