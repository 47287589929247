import React, { useRef, useState } from 'react'
import { useTranslation } from "react-i18next";
import "../i18n";

const FileUploader = ({ onFileSelectError, onFileSelectSuccess }) => {
  const { t } = useTranslation("translation");
  const fileInput = useRef(null)
  const [filename, setFileName] = useState(t("form.import-no-file"))

  const handleFileInput = (e) => {
    const file = e.target.files[0];
    const fileSize = (file.size / (1024 * 1024)).toFixed(2)
    if (fileSize > 1)
      onFileSelectError({
        title: "File size cannot exceed more than 1MB",
        error: true,
        shown: true
      });
    else {
      onFileSelectSuccess(file);
      setFileName(file.name)
    }
  };

  return (
    <div className="file-uploader mb-7">

      <label htmlFor="fileInput" role="button" className="btn px-3.5 py-2.5 bg-white font-semibold text-sm bg-gray-100">
        {t("form.import-select-file")}
      </label>
      <span className="ml-3">{filename}</span>
      <input type="file" id="fileInput" style={{ display: "none" }} onChange={handleFileInput} className="block w-full text-sm text-gray-500 file:mr-4 file:py-2 file:px-4 file:border-0 file:text-sm file:font-semibold file:bg-black-50 file:text-black-700 hover:file:bg-black-100" />
      <button onClick={e => fileInput.current && fileInput.current.click()} className="btn btn-primary" />
    </div>
  )
}

export default FileUploader;