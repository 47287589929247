import { Routes, Route, Navigate, HashRouter } from "react-router-dom";
import { Helmet, HelmetProvider } from 'react-helmet-async';
import './App.css';
import Layout from '@/layouts/AdminLayout';
import ScrollToTop from '@/utils/admin/scrollToTop';
import React, { Suspense } from 'react';

/* Pages */
import Home from '@/pages/admin/Home';
import BundleList from '@/pages/admin/BundleList';
import CrossLocaleBundleList from '@/pages/admin/CrossLocaleBundleList';
import Error404 from '@/pages/404';
import SignInPage from '@/pages/admin/SignInPage';
import BundleForm from '@/pages/admin/BundleFormV2';


import { useTranslation } from 'react-i18next';
import './i18n';
import LayoutV2 from "./layouts/AdminLayoutV2";
import BundleImport from "./pages/admin/BundleImport";

function App() {
  const { t } = useTranslation('translation');
  return (
    <Suspense fallback={<Loader label={t("loading")} />}>
      <HelmetProvider context={{}}>
        <Helmet>
          <title>{t("page-title")}</title>
          <meta name="description" content="App Description" />
          <meta name="theme-color" content="#000" />
          <link
            rel="shortcut icon"
            href="https://static.thenorthface.eu/ui/www/images/favicon.hash-eb9634ef626b8b5129b4295abdeb6e2f.ico"
          />
        </Helmet>
        <div className="App">
          <HashRouter>
            <ScrollToTop />

            <Routes>
              {/* Public Routes */}
              <Route path="/login" element={<Layout />}>
                <Route path="/login" element={<SignInPage />} />
              </Route>

              <Route path="/" element={<LayoutV2 />}>
                <Route
                  path="/"
                  element={
                    <PrivateRoute>
                      <Home />
                    </PrivateRoute>
                  }
                />
                <Route
                  path="/list"
                  element={
                    <PrivateRoute>
                      <BundleList />
                    </PrivateRoute>
                  }
                />
                <Route
                  path="/list/cross-locale"
                  element={
                    <PrivateRoute>
                      <CrossLocaleBundleList />
                    </PrivateRoute>
                  }
                />
                <Route
                  path="/bundles/new"
                  element={
                    <PrivateRoute>
                      <BundleForm create multiLocale/>
                    </PrivateRoute>
                  }
                />
                <Route
                  path="/bundles/edit/:key"
                  element={
                    <PrivateRoute>
                      <BundleForm edit />
                    </PrivateRoute>
                  }
                />
                <Route
                  path="/bundles/cross-locale/edit/:key"
                  element={
                    <PrivateRoute>
                      <BundleForm edit multiLocale/>
                    </PrivateRoute>
                  }
                />
                <Route
                  path="/bundles/import"
                  element={
                    <PrivateRoute>
                      <BundleImport/>
                    </PrivateRoute>
                  }
                />

                <Route path="*" element={<Error404 />} />
              </Route>
            </Routes>
          </HashRouter>
        </div>
      </HelmetProvider>
    </Suspense>
  );
}

export default App;

const PrivateRoute = ({children}) => {
  const token = localStorage.getItem('accessToken');
  if(!token) return <Navigate to="/login" replace />;
  return children
}

// loading component for suspense fallback
const Loader = (loading) => (
  <div className="App">
    <div>{loading}</div>
  </div>
);