import { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import {
    createNewBundle,
    getBundleInputs,
    getCrossLocaleBundleInputs,
    updateBundle,
    updateCrossLocaleBundle,
} from "@/utils/admin/fetch";
import Error404 from "@/pages/404";
import { LOCALES, ACTIVITIES, GENDERS } from "@/const";
import Alert from "@/components/admin/Alert";
import { validateInputs, getUpdatedAtTimeString } from "@/utils/admin/form";
import { handleLogout } from "@/utils/admin/logout";
import { validateToken } from "@/utils/admin/fetch";

import * as Tooltip from '@radix-ui/react-tooltip';
import '../../styles/tooltip.css';
import { ReactComponent as QuestionMarkIcon } from '@/assets/svg/questionMark.svg'

import { useTranslation } from "react-i18next";
import "../../i18n";

const BundleForm = (props) => {
    const [inputs, setInputs] = useState({});
    // for edit / duplicate
    const { key } = useParams();
    const [id, view_id] = key?.split("-") || [];
    const [notFound, setNotFound] = useState(false);
    const [thirdProductForm, setThirdProductForm] = useState(false);

    const [productApparel, setProductApparel] = useState({})

    const [translationPopUpShown, setTranslationPopUpShown] = useState(false)

    /* fetch result */
    const [alert, setAlert] = useState(null);

    const [waitingResponse, setWaitingResponse] = useState(false);

    const { t } = useTranslation("translation");

    useEffect(() => {
        validateToken()
            .then(res => {
                if (!res) handleLogout()
            })
    }, [])

    useEffect(() => {
        if (props.edit || props.duplicate) {
            const getBundleInputsThen = (res) => {
                if (res) {
                    setInputs(res);
                    if (res.bundleProduct3SKU) setThirdProductForm(true);
                } else {
                    setNotFound(true);
                }
            }
            if (props.multiLocale) {
                getCrossLocaleBundleInputs(id).then(getBundleInputsThen);
            } else {
                getBundleInputs(id, view_id).then(getBundleInputsThen);
            }
        }
    }, [id, props.duplicate, props.edit, view_id]);

    useEffect(() => {
        // default bundle product image type
        if (props.create) {
            setInputs({
                ...inputs,
                bundleProduct1Image1Type: "hero",
                bundleProduct1Image2Type: "alt1",
                bundleProduct2Image1Type: "hero",
                bundleProduct2Image2Type: "alt1",
            })
        }
    }, [])

    const handleChange = ({ target }) => {
        const name = target.name;
        if (target.type === "checkbox") {
            const checked = target.checked;
            setInputs((values) => ({ ...values, [name]: checked }));
        } else {
            const value = target.value;
            setInputs((values) => ({ ...values, [name]: value }));
        }

    };

    const handleChangeUppercase = ({ target }) => {
        const name = target.name;
        const value = target.value.toUpperCase();
        setInputs((values) => ({ ...values, [name]: value }));
    };

    const toggleInputsActivity = newActivity => {
        let activityArray = inputs.bundleActivity?.split(';') || []
        if (activityArray.includes(newActivity)) {
            activityArray = activityArray.filter(a => a !== newActivity)
        } else {
            if (inputs.bundleActivity) activityArray.push(newActivity)
            else activityArray = [newActivity]
        }
        setInputs({ ...inputs, bundleActivity: activityArray.join(';') })
    }

    const handleSubmit = (event) => {
        event.preventDefault();

        if (validateInputs(inputs)) {
            setWaitingResponse(true);
            if (props.edit) {
                const updateBundleThen = (res) => {
                    if (res.status !== 401) {
                        setWaitingResponse(false);
                        handleAlert(res);
                    } else handleLogout();
                }

                if (props.multiLocale)
                    updateCrossLocaleBundle({ ...inputs, id, view_id }).then(updateBundleThen);
                else
                    updateBundle({ ...inputs, id, view_id }).then(updateBundleThen);
            }
            if (props.create || props.duplicate) {
                createNewBundle(inputs).then((res) => {
                    if (res.status !== 401) {
                        setWaitingResponse(false);
                        handleAlert(res);
                    } else handleLogout();
                });
            }
        } else {
            setAlert({
                shown: true,
                error: true,
                title: t("form.input-not-valid"),
                actions: {
                    primary: {
                        callback: () => setAlert({ ...alert, shown: false }),
                        title: t("table.alert.close"),
                    },
                },
            });
        }
    };

    const handleAlert = (res) => {
        if (res.status !== 200)
            setAlert({
                shown: true,
                error: true,
                message: res.message,
                title: `${t("form.saving-error")} (${res.status})`,
                actions: {
                    primary: {
                        callback: () => setAlert({ ...alert, shown: false }),
                        title: t("table.alert.close"),
                    },
                },
            });
        else
            setAlert({
                shown: true,
                error: false,
                title: t("form.update-saved"),
                message: t("form.update-saved-detail"),
                actions: {
                    primary: {
                        callback: () => (window.location.href = "/"),
                        title: t("form.back-to-list"),
                    },
                },
            });
    };

    const getCTAText = () => {
        if (props.edit) return t("form.edit");
        if (props.duplicate) return t("form.create-duplicate");
        else return t("form.create");
    };
    const getCTATextClass = () => {
        return waitingResponse ? "invisible" : "";
    };

    const handleThirdProduct = (event) => {
        event.preventDefault();
        setThirdProductForm(true);
        addThirdProductImageTypes();
    };

    const addThirdProductImageTypes = () => {
        setInputs({
            ...inputs,
            bundleProduct3Image1Type: "hero",
            bundleProduct3Image2Type: "alt1"
        })

    }

    const removeThirdProduct = (event) => {
        event.preventDefault();
        setThirdProductForm(false);
        delete inputs.bundleProduct3SKU;
        delete inputs.bundleProduct3Variant;
        delete inputs.bundleProduct3Fallback
        delete inputs.bundleProduct3Image1Type
        delete inputs.bundleProduct3Image2Type
        delete productApparel.Product3
        setInputs(inputs);
        setProductApparel(productApparel)
    };

    const toggleSelectAll = event => {
        event.preventDefault();
        if (inputs.locales?.length !== LOCALES.length)
            setInputs({ ...inputs, locales: LOCALES })
        else {
            setInputs({ ...inputs, locales: [] })
        }
    }
    const handleLocaleSelection = event => {
        if (inputs.locales?.length) {
            if (inputs.locales?.includes(event.target.value)) {
                setInputs({ ...inputs, locales: inputs.locales?.filter(l => l !== event.target.value) })
            }
            else {
                setInputs({ ...inputs, locales: [...inputs?.locales, event.target.value] })
            }
        } else setInputs({ ...inputs, locales: [event.target.value] })

    }

    const handleApparelChange = ({ target }) => {
        const inputKeys = {
            image1Type: `bundle${target.name}Image1Type`,
            image2Type: `bundle${target.name}Image2Type`
        }

        setProductApparel({
            ...productApparel,
            [target.name]: target.checked
        })

        if (target.checked) {
            setInputs({
                ...inputs,
                [inputKeys.image1Type]: 'alt20',
                [inputKeys.image2Type]: 'hero',
            })
        } else {
            setInputs({
                ...inputs,
                [inputKeys.image1Type]: 'hero',
                [inputKeys.image2Type]: 'alt1',
            })
        }



    }
    const openTranslationPopUp = (event) => {
        event.preventDefault()
        setTranslationPopUpShown(true)
    }

    const bundleType = props.multiLocale ? t("form.cross-locale-bundle") : t("form.bundle")

    return !notFound ? (
        <div className="page-content">
            <Alert
                title={alert?.title}
                message={alert?.message}
                error={alert?.error}
                shown={alert?.shown}
                actions={alert?.actions}
            />

            <form onSubmit={handleSubmit} className="container mx-auto">
                {(props.multiLocale && translationPopUpShown) && <TranslationPopUp
                    locales={inputs.locales}
                    translations={Object.keys(inputs).reduce((acc,key) => {
                        if(key.includes('bundleName-')){
                            acc[key] = inputs[key]
                        }
                        return acc
                    },{})}
                    saveNames={(data)=>setInputs({...inputs, ...data})}
                    closePopUp={()=>setTranslationPopUpShown(false)}
                />}

                <div className="divide-y divide-gray-200">
                    <div>
                        <div className="mb-12">
                            <h3 className="text-lg leading-6 font-medium text-gray-900">
                                {getCTAText()} {t("form.bundle")}
                            </h3>
                            <p className="mt-1 max-w-2xl text-sm text-gray-500">
                                {props.create ? t("form.create-text") : props.multiLocale ? t("form.cross-locale-update-text") : t("form.update-text")}
                            </p>
                            <p className="mt-2.5 italic max-w-2xl text-sm text-gray-500">
                                {t("form.mandatory-information")}
                            </p>
                        </div>

                        <div className="mb-5">
                            <h3 className="text-lg leading-6 font-medium text-gray-900 mt-5">
                                {bundleType} {t("form.details")}
                            </h3>
                            <p className="mt-1 max-w-2xl text-sm text-gray-500">
                                {t("form.info-text")}
                            </p>
                        </div>

                        <div className="group bg-gray-100 px-8">

                            <div className="sm:grid sm:grid-cols-3 sm:gap-4 sm:items-start py-5">
                                <label
                                    htmlFor="bundleLocale"
                                    className="block text-sm font-medium text-gray-700 sm:mt-px sm:pt-2"
                                >
                                    {t("form.locale")} <span className="text-lg">*</span>
                                </label>
                                <div className={`mt-1 sm:mt-0 sm:col-span-2 flex flex-wrap gap-5${props.edit && !props.multiLocale ? ' opacity-50 pointer-events-none' : ''}`}>
                                    {LOCALES.map((locale, index) => (
                                        <div key={index} className="flex grow-0 items-center text-sm text-gray-700">
                                            <input id={`cb-${locale}`}
                                                type="checkbox"
                                                value={locale}
                                                onChange={handleLocaleSelection}
                                                checked={inputs.locales?.includes(locale) || false}
                                                className="w-4 h-4 text-black bg-white border-gray-300 focus:ring-black dark:focus:ring-black focus:ring-2" />
                                            <label htmlFor={`cb-${locale}`} className="ml-2 text-sm font-medium">{locale}</label>
                                        </div>
                                    ))}
                                </div>
                            </div>
                            {props.multiLocale && <div className="flex pb-5">
                                <button className="text-sm font-semibold p-2 bg-gray-200" onClick={toggleSelectAll}>{inputs.locales?.length === LOCALES.length ? t("form.deselect-all") : t("form.select-all")}</button>
                            </div>}

                            <div className="sm:grid sm:grid-cols-3 sm:gap-4 sm:items-start sm:border-t sm:border-gray-200 py-5">
                                <label
                                    htmlFor="bundleId"
                                    className="block text-sm font-medium text-gray-700 sm:mt-px sm:pt-2"
                                >
                                    {t("form.bundle-id")} <span className="text-lg">*</span> <small>(Backend)</small>
                                    <Tooltip.Provider>
                                        <Tooltip.Root>
                                            <Tooltip.Trigger asChild>
                                                <button className="IconButton bg-gray-500 flex" style={{ flex: "0 0 25px" }} onClick={event => event.preventDefault()}>
                                                    <QuestionMarkIcon />
                                                </button>
                                            </Tooltip.Trigger>
                                            <Tooltip.Portal>
                                                <Tooltip.Content className="TooltipContent" sideOffset={5} side="bottom">
                                                    <div className="text-xs" dangerouslySetInnerHTML={{ __html: t("form.bundle-name-tooltip") }}></div>
                                                    <Tooltip.Arrow className="TooltipArrow" />
                                                </Tooltip.Content>
                                            </Tooltip.Portal>
                                        </Tooltip.Root>
                                    </Tooltip.Provider>
                                </label>

                                <div className="mt-1 sm:mt-0 sm:col-span-2">
                                    <input
                                        disabled={!props.multiLocale}
                                        required
                                        type="text"
                                        name="bundleId"
                                        id="bundleId"
                                        autoComplete="bundleId"
                                        placeholder={t("form.bundle-name-placeholder")}
                                        value={inputs.bundleId || ""}
                                        onChange={handleChange}
                                        className={`flex-1 block w-full focus:ring-black focus:border-black min-w-0  sm:text-sm border-gray-300 p-3 ${!props.multiLocale && 'opacity-50'}`}
                                    />
                                </div>
                            </div>

                            {props.multiLocale && <div className="sm:grid sm:grid-cols-3 sm:gap-4 sm:items-start sm:border-t sm:border-gray-200 py-5">
                                <label className="block text-sm font-medium text-gray-700 sm:mt-px sm:pt-2">
                                    {t('form.add-bundle-name-translations')} <small>(Frontend)</small>
                                </label>

                                <div className="mt-1 sm:mt-0 sm:col-span-2">
                                    {props.multiLocale && <button
                                        class={`text-sm font-semibold mt-2 ${!inputs.locales?.length ? 'opacity-50' : 'hover:opacity-50 duration-200 cursor-pointer'}`}
                                        disabled={!inputs.locales?.length}
                                        onClick={openTranslationPopUp}>
                                            + {t('form.add-bundle-name-translations-cta')}
                                    </button>}
                                </div>
                            </div>}

                            {!props.multiLocale && <div className="sm:grid sm:grid-cols-3 sm:gap-4 sm:items-start sm:border-t sm:border-gray-200 py-5">
                                <label
                                    htmlFor="bundleName"
                                    className="block text-sm font-medium text-gray-700 sm:mt-px sm:pt-2"
                                >
                                    {t("form.bundle-name")} <span className="text-lg">*</span>
                                    {/* <Tooltip.Provider>
                                        <Tooltip.Root>
                                            <Tooltip.Trigger asChild>
                                                <button className="IconButton bg-gray-500 flex" style={{ flex: "0 0 25px" }} onClick={event => event.preventDefault()}>
                                                    <QuestionMarkIcon />
                                                </button>
                                            </Tooltip.Trigger>
                                            <Tooltip.Portal>
                                                <Tooltip.Content className="TooltipContent" sideOffset={5} side="bottom">
                                                    <div className="text-xs" dangerouslySetInnerHTML={{ __html: t("form.bundle-name-tooltip") }}></div>
                                                    <Tooltip.Arrow className="TooltipArrow" />
                                                </Tooltip.Content>
                                            </Tooltip.Portal>
                                        </Tooltip.Root>
                                    </Tooltip.Provider> */}
                                </label>

                                <div className="mt-1 sm:mt-0 sm:col-span-2">
                                    <input
                                        required
                                        type="text"
                                        name="bundleName"
                                        id="bundleName"
                                        autoComplete="bundleName"
                                        placeholder={t("form.bundle-name-placeholder")}
                                        value={inputs.bundleName || ""}
                                        onChange={handleChange}
                                        className="flex-1 block w-full focus:ring-black focus:border-black min-w-0  sm:text-sm border-gray-300 p-3"
                                    />
                                </div>
                            </div>}

                            {/* <div className="sm:grid sm:grid-cols-3 sm:gap-4 sm:items-start sm:border-t sm:border-gray-200 py-5">
                                <label
                                htmlFor="bundleDescription"
                                className="block text-sm font-medium text-gray-700 sm:mt-px sm:pt-2"
                                >
                                {t("form.bundle-description")}
                                </label>
                                <div className="mt-1 sm:mt-0 sm:col-span-2">
                                <textarea
                                    type="text"
                                    name="bundleDescription"
                                    id="bundleDescription"
                                    autoComplete="bundleDescription"
                                    value={inputs.bundleDescription || ""}
                                    onChange={handleChange}
                                    className="flex-1 block w-full focus:ring-black focus:border-black min-w-0  sm:text-sm border-gray-300 p-3"
                                />
                                </div>
                            </div> */}

                            <div className="sm:grid sm:grid-cols-3 sm:gap-4 sm:items-start sm:border-t sm:border-gray-200 py-5">
                                <label
                                    htmlFor="bundleGender"
                                    className="block text-sm font-medium text-gray-700 sm:mt-px sm:pt-2"
                                >
                                    {t("form.gender")} <span className="text-lg">*</span>
                                </label>
                                <div className="mt-1 sm:mt-0 sm:col-span-2">
                                    <select
                                        id="bundleGender"
                                        name="bundleGender"
                                        required
                                        className={`mt-1 block w-full text-base border-gray-300 focus:outline-none focus:ring-black focus:border-black sm:text-sm p-3`}
                                        value={inputs.bundleGender || ""}
                                        onChange={handleChange}
                                    >
                                        <option value="" hidden>{t("form.gender-placeholder")}</option>
                                        {GENDERS.map((item) => (
                                            <option key={item.id} value={item.id}>
                                                {t(`form.${item.i18n}`)}
                                            </option>
                                        ))}
                                    </select>
                                </div>
                            </div>

                            <div className="sm:grid sm:grid-cols-3 sm:gap-4 sm:items-start sm:border-t sm:border-gray-200 py-5">
                                <label
                                    htmlFor="bundleAccessories"
                                    className="block text-sm font-medium text-gray-700 sm:mt-px sm:pt-2"
                                >
                                    {t("form.accessories-bundle")}
                                </label>
                                <div className="mt-1 sm:mt-0 sm:col-span-2 sm:pt-2 pt-3">
                                    <label
                                        htmlFor="accessories-toggle"
                                        className="inline-flex relative items-center cursor-pointer"
                                    >
                                        <input
                                            onChange={handleChange}
                                            type="checkbox"
                                            name="bundleAccessories"
                                            checked={inputs.bundleAccessories || false}
                                            id="accessories-toggle"
                                            className="sr-only peer"
                                        />
                                        <div className="w-11 h-6 bg-gray-500 peer-focus:outline-none peer-focus:ring-4 peer-focus:ring-gray-200 dark:peer-focus:ring-gray-200 rounded-full peer dark:bg-gray-300 peer-checked:after:translate-x-full peer-checked:after:border-white after:content-[''] after:absolute after:top-[2px] after:left-[2px] after:bg-white after:border-gray-300 after:border after:rounded-full after:h-5 after:w-5 after:transition-all dark:border-gray-600 peer-checked:bg-black"></div>
                                        <span className="ml-3 text-sm text-sm text-gray-500">
                                            {inputs.bundleAccessories
                                                ? 'Y'
                                                : 'N'
                                            }
                                        </span>
                                    </label>
                                </div>
                            </div>

                            <div className="sm:grid sm:grid-cols-3 sm:gap-4 sm:items-start sm:border-t sm:border-gray-200 py-5">
                                <label
                                    htmlFor="bundleActivity"
                                    className="block text-sm font-medium text-gray-700 sm:mt-px sm:pt-2"
                                >
                                    {t("form.activity")}
                                </label>

                                <div className="mt-1 sm:mt-0 sm:col-span-2 flex flex-col">
                                    {inputs.bundleActivity ? (
                                        <div className="flex flex-1 block w-full focus:ring-black focus:border-black min-w-0 sm:text-sm whitespace-nowrap flex-wrap inline-flex gap-2 mb-3">
                                            {inputs.bundleActivity?.split(";").map((activity) => (
                                                <div key={activity}>
                                                    <span className="inline-flex items-center rounded-full bg-gray-100 py-0.5 pl-2.5 pr-1 text-sm font-medium text-gray-700 border border-gray-300 bg-white">
                                                        {activity}  
                                                        <button
                                                            type="button"
                                                            onClick={() => toggleInputsActivity(activity)}
                                                            className="ml-0.5 inline-flex h-4 w-4 flex-shrink-0 items-center justify-center rounded-full text-gray-500 hover:bg-gray-200 hover:text-gray-500 focus:bg-gray-500 focus:text-white focus:outline-none"
                                                        >
                                                            <span className="sr-only">
                                                                Remove large option
                                                            </span>
                                                            <svg
                                                                className="h-2 w-2"
                                                                stroke="currentColor"
                                                                fill="none"
                                                                viewBox="0 0 8 8"
                                                            >
                                                                <path
                                                                    strokeLinecap="round"
                                                                    strokeWidth="1.5"
                                                                    d="M1 1l6 6m0-6L1 7"
                                                                />
                                                            </svg>
                                                        </button>
                                                    </span>
                                                </div>
                                            ))}
                                        </div>
                                    ) : null}

                                    <select
                                        onChange={({ target }) =>
                                            toggleInputsActivity(target.value)
                                        }
                                        value=""
                                        className={`mt-1 block w-full text-base border-gray-300 focus:outline-none focus:ring-black focus:border-black sm:text-sm p-3`}
                                    >
                                        <option value="">{t("form.activity-placeholder")}</option>
                                        {ACTIVITIES.filter((a) =>
                                            inputs.bundleActivity
                                                ? inputs.bundleActivity?.indexOf(a) === -1
                                                : true
                                        ).map((activity, index) => (
                                            <option value={activity} key={index}>
                                                {activity}
                                            </option>
                                        ))}
                                    </select>
                                </div>
                            </div>

                            <div className="sm:grid sm:grid-cols-3 sm:gap-4 sm:items-start sm:border-t sm:border-gray-200 py-5">
                                <label
                                    htmlFor="productCode"
                                    className="block text-sm font-medium text-gray-700 sm:mt-px sm:pt-2 flex items-center max-w-xs">
                                    {
                                        t("form.products").replace('%a', props.create ? t("form.new") : '').replace('%b', bundleType)
                                    }

                                    <Tooltip.Provider>
                                        <Tooltip.Root>
                                            <Tooltip.Trigger asChild>
                                                <button className="IconButton bg-gray-500 flex" style={{ flex: "0 0 25px" }} onClick={event => event.preventDefault()}>
                                                    <QuestionMarkIcon />
                                                </button>
                                            </Tooltip.Trigger>
                                            <Tooltip.Portal>
                                                <Tooltip.Content className="TooltipContent" sideOffset={5} side="bottom">
                                                    <div className="text-xs" dangerouslySetInnerHTML={{ __html: t("form.products-tooltip") }}></div>
                                                    <Tooltip.Arrow className="TooltipArrow" />
                                                </Tooltip.Content>
                                            </Tooltip.Portal>
                                        </Tooltip.Root>
                                    </Tooltip.Provider>
                                </label>

                                <div className="mt-1 sm:mt-0 sm:col-span-2">
                                    <span className="text-xs text-gray-700">
                                        {t("form.product-1")}
                                    </span>
                                    {props.create && <div className="mt-1 sm:mt-0 sm:col-span-2 sm:pt-2 pt-3">
                                        <label
                                            htmlFor="product-1-apparel"
                                            className="inline-flex relative items-center cursor-pointer"
                                        >
                                            <input
                                                onChange={handleApparelChange}
                                                type="checkbox"
                                                name="Product1"
                                                checked={productApparel.Product1 || false}
                                                id="product-1-apparel"
                                                className="sr-only peer"
                                            />
                                            <div className="w-11 h-6 bg-gray-500 peer-focus:outline-none peer-focus:ring-4 peer-focus:ring-gray-200 dark:peer-focus:ring-gray-200 rounded-full peer dark:bg-gray-300 peer-checked:after:translate-x-full peer-checked:after:border-white after:content-[''] after:absolute after:top-[2px] after:left-[2px] after:bg-white after:border-gray-300 after:border after:rounded-full after:h-5 after:w-5 after:transition-all dark:border-gray-600 peer-checked:bg-black"></div>
                                            <span className="ml-3 text-sm text-sm text-gray-500">
                                                {productApparel.Product1
                                                    ? 'Apparel'
                                                    : 'Not Apparel'
                                                }
                                            </span>
                                        </label>
                                    </div>}
                                    <div className="flex mt-1 mb-2 flex-wrap product-info">
                                        <input
                                            required
                                            type="text"
                                            name="bundleProduct1SKU"
                                            id="bundleProduct1SKU"
                                            placeholder={t("form.style-code-placeholder") + ' *'}
                                            maxLength={4}
                                            value={inputs.bundleProduct1SKU || ""}
                                            onChange={handleChangeUppercase}
                                            className="max-w-lg block w-full shadow-sm focus:ring-black focus:border-black sm:text-sm border-gray-300 p-3 grow product-input"
                                        />
                                        <input
                                            type="text"
                                            name="bundleProduct1Variant"
                                            id="bundleProduct1Variant"
                                            placeholder={t("form.variant-placeholder")}
                                            maxLength={3}
                                            value={inputs.bundleProduct1Variant || ""}
                                            onChange={handleChangeUppercase}
                                            className="max-w-lg block w-full shadow-sm focus:ring-black focus:border-black sm:text-sm border-gray-300 p-3 ml-2 grow product-input"
                                        />
                                        <input
                                            type="text"
                                            name="bundleProduct1Fallback"
                                            id="bundleProduct1Fallback"
                                            placeholder={t("form.color-fallback-placeholder")}
                                            maxLength={3}
                                            value={inputs.bundleProduct1Fallback || ""}
                                            onChange={handleChangeUppercase}
                                            className="max-w-lg block w-full shadow-sm focus:ring-black focus:border-black sm:text-sm border-gray-300 p-3 ml-2 grow product-input"
                                        />
                                        <input
                                            required
                                            type="text"
                                            name="bundleProduct1Image1Type"
                                            id="bundleProduct1Image1Type"
                                            placeholder={t("form.image1-placeholder") + ' *'}
                                            maxLength={5}
                                            value={inputs.bundleProduct1Image1Type || ""}
                                            onChange={handleChange}
                                            className="max-w-lg block w-full shadow-sm focus:ring-black focus:border-black sm:text-sm border-gray-300 p-3 ml-2 product-images-input"
                                        />
                                        <input
                                            required
                                            type="text"
                                            name="bundleProduct1Image2Type"
                                            id="bundleProduct1Image2Type"
                                            placeholder={t("form.image2-placeholder") + ' *'}
                                            maxLength={5}
                                            value={inputs.bundleProduct1Image2Type || ""}
                                            onChange={handleChange}
                                            className="max-w-lg block w-full shadow-sm focus:ring-black focus:border-black sm:text-sm border-gray-300 p-3 ml-2 product-images-input"
                                        />
                                    </div>

                                    <span className="text-xs text-gray-700 mt-2.5">
                                        {t("form.product-2")}
                                    </span>
                                    {props.create && <div className="mt-1 sm:mt-0 sm:col-span-2 sm:pt-2 pt-3">
                                        <label
                                            htmlFor="product-2-apparel"
                                            className="inline-flex relative items-center cursor-pointer"
                                        >
                                            <input
                                                onChange={handleApparelChange}
                                                type="checkbox"
                                                name="Product2"
                                                checked={productApparel.Product2 || false}
                                                id="product-2-apparel"
                                                className="sr-only peer"
                                            />
                                            <div className="w-11 h-6 bg-gray-500 peer-focus:outline-none peer-focus:ring-4 peer-focus:ring-gray-200 dark:peer-focus:ring-gray-200 rounded-full peer dark:bg-gray-300 peer-checked:after:translate-x-full peer-checked:after:border-white after:content-[''] after:absolute after:top-[2px] after:left-[2px] after:bg-white after:border-gray-300 after:border after:rounded-full after:h-5 after:w-5 after:transition-all dark:border-gray-600 peer-checked:bg-black"></div>
                                            <span className="ml-3 text-sm text-sm text-gray-500">
                                                {productApparel.Product2
                                                    ? 'Apparel'
                                                    : 'Not Apparel'
                                                }
                                            </span>
                                        </label>
                                    </div>}
                                    <div className="flex mt-1 mb-2 flex-wrap product-info">
                                        <input
                                            required
                                            type="text"
                                            name="bundleProduct2SKU"
                                            id="bundleProduct2SKU"
                                            placeholder={t("form.style-code-placeholder") + ' *'}
                                            maxLength={4}
                                            value={inputs.bundleProduct2SKU || ""}
                                            onChange={handleChangeUppercase}
                                            className="max-w-lg block w-full shadow-sm focus:ring-black focus:border-black sm:text-sm border-gray-300 p-3 grow product-input"
                                        />
                                        <input
                                            type="text"
                                            name="bundleProduct2Variant"
                                            id="bundleProduct2Variant"
                                            placeholder={t("form.variant-placeholder")}
                                            maxLength={3}
                                            value={inputs.bundleProduct2Variant || ""}
                                            onChange={handleChangeUppercase}
                                            className="max-w-lg block w-full shadow-sm focus:ring-black focus:border-black sm:text-sm border-gray-300 p-3 ml-2 grow product-input"
                                        />
                                        <input
                                            type="text"
                                            name="bundleProduct2Fallback"
                                            id="bundleProduct2Fallback"
                                            placeholder={t("form.color-fallback-placeholder")}
                                            maxLength={3}
                                            value={inputs.bundleProduct2Fallback || ""}
                                            onChange={handleChangeUppercase}
                                            className="max-w-lg block w-full shadow-sm focus:ring-black focus:border-black sm:text-sm border-gray-300 p-3 ml-2 grow product-input"
                                        />
                                        <input
                                            required
                                            type="text"
                                            name="bundleProduct2Image1Type"
                                            id="bundleProduct2Image1Type"
                                            placeholder={t("form.image1-placeholder") + ' *'}
                                            maxLength={5}
                                            value={inputs.bundleProduct2Image1Type || ""}
                                            onChange={handleChange}
                                            className="max-w-lg block w-full shadow-sm focus:ring-black focus:border-black sm:text-sm border-gray-300 p-3 ml-2 product-images-input"
                                        />
                                        <input
                                            required
                                            type="text"
                                            name="bundleProduct2Image2Type"
                                            id="bundleProduct2Image2Type"
                                            placeholder={t("form.image2-placeholder") + ' *'}
                                            maxLength={5}
                                            value={inputs.bundleProduct2Image2Type || ""}
                                            onChange={handleChange}
                                            className="max-w-lg block w-full shadow-sm focus:ring-black focus:border-black sm:text-sm border-gray-300 p-3 ml-2 product-images-input"
                                        />
                                    </div>
                                    {!thirdProductForm ? (
                                        <button
                                            onClick={handleThirdProduct}
                                            className="text-gray-700 hover:opacity-75 duration-200 mt-3 flex items-center"
                                        >
                                            <svg
                                                xmlns="http://www.w3.org/2000/svg"
                                                xlink="http://www.w3.org/1999/xlink"
                                                version="1.1"
                                                id="Layer_1"
                                                x="0px"
                                                y="0px"
                                                viewBox="0 0 455 455"
                                                width={15}
                                                fill="currentColor"
                                            >
                                                <polygon points="455,212.5 242.5,212.5 242.5,0 212.5,0 212.5,212.5 0,212.5 0,242.5 212.5,242.5 212.5,455 242.5,455 242.5,242.5   455,242.5 " />
                                            </svg>
                                            <span className="ml-1">{t("form.add-product")}</span>
                                        </button>
                                    ) : (
                                        <>
                                            <span className="text-xs text-gray-700 mt-2.5">
                                                {t("form.product-3")}
                                            </span>
                                            {props.create && <div className="mt-1 sm:mt-0 sm:col-span-2 sm:pt-2 pt-3">
                                                <label
                                                    htmlFor="product-3-apparel"
                                                    className="inline-flex relative items-center cursor-pointer"
                                                >
                                                    <input
                                                        onChange={handleApparelChange}
                                                        type="checkbox"
                                                        name="Product3"
                                                        checked={productApparel.Product3 || false}
                                                        id="product-3-apparel"
                                                        className="sr-only peer"
                                                    />
                                                    <div className="w-11 h-6 bg-gray-500 peer-focus:outline-none peer-focus:ring-4 peer-focus:ring-gray-200 dark:peer-focus:ring-gray-200 rounded-full peer dark:bg-gray-300 peer-checked:after:translate-x-full peer-checked:after:border-white after:content-[''] after:absolute after:top-[2px] after:left-[2px] after:bg-white after:border-gray-300 after:border after:rounded-full after:h-5 after:w-5 after:transition-all dark:border-gray-600 peer-checked:bg-black"></div>
                                                    <span className="ml-3 text-sm text-sm text-gray-500">
                                                        {productApparel.Product3
                                                            ? 'Apparel'
                                                            : 'Not Apparel'
                                                        }
                                                    </span>
                                                </label>
                                            </div>}
                                            <div className="flex mt-1 mb-2 flex-wrap product-info relative">
                                                <input
                                                    required
                                                    type="text"
                                                    name="bundleProduct3SKU"
                                                    id="bundleProduct3SKU"
                                                    placeholder={t("form.style-code-placeholder")}
                                                    maxLength={4}
                                                    value={inputs.bundleProduct3SKU || ""}
                                                    onChange={handleChangeUppercase}
                                                    className="max-w-lg block w-full shadow-sm focus:ring-black focus:border-black sm:text-sm border-gray-300 p-3 grow product-input"
                                                />
                                                <input
                                                    type="text"
                                                    name="bundleProduct3Variant"
                                                    id="bundleProduct3Variant"
                                                    placeholder={t("form.variant-placeholder")}
                                                    maxLength={3}
                                                    value={inputs.bundleProduct3Variant || ""}
                                                    onChange={handleChangeUppercase}
                                                    className="max-w-lg block w-full shadow-sm focus:ring-black focus:border-black sm:text-sm border-gray-300 p-3 grow product-input"
                                                />
                                                <input
                                                    type="text"
                                                    name="bundleProduct3Fallback"
                                                    id="bundleProduct3Fallback"
                                                    placeholder={t("form.color-fallback-placeholder")}
                                                    maxLength={3}
                                                    value={inputs.bundleProduct3Fallback || ""}
                                                    onChange={handleChangeUppercase}
                                                    className="max-w-lg block w-full shadow-sm focus:ring-black focus:border-black sm:text-sm border-gray-300 p-3 grow product-input"
                                                />
                                                <input
                                                    required
                                                    type="text"
                                                    name="bundleProduct3Image1Type"
                                                    id="bundleProduct3Image1Type"
                                                    placeholder={t("form.image1-placeholder")}
                                                    maxLength={5}
                                                    value={inputs.bundleProduct3Image1Type || ""}
                                                    onChange={handleChange}
                                                    className="max-w-lg block w-full shadow-sm focus:ring-black focus:border-black sm:text-sm border-gray-300 p-3 ml-2 product-images-input"
                                                />
                                                <input
                                                    required
                                                    type="text"
                                                    name="bundleProduct3Image2Type"
                                                    id="bundleProduct3Image2Type"
                                                    placeholder={t("form.image2-placeholder")}
                                                    maxLength={5}
                                                    value={inputs.bundleProduct3Image2Type || ""}
                                                    onChange={handleChange}
                                                    className="max-w-lg block w-full shadow-sm focus:ring-black focus:border-black sm:text-sm border-gray-300 p-3 ml-2 product-images-input"
                                                />
                                                <button
                                                    className="text-gray-700 hover:opacity-75 duration-200 rotate-45"
                                                    onClick={removeThirdProduct}>
                                                    <svg
                                                        xmlns="http://www.w3.org/2000/svg"
                                                        xlink="http://www.w3.org/1999/xlink"
                                                        version="1.1"
                                                        id="Layer_1"
                                                        x="0px"
                                                        y="0px"
                                                        viewBox="0 0 455 455"
                                                        width={15}
                                                        fill="currentColor"
                                                    >
                                                        <polygon points="455,212.5 242.5,212.5 242.5,0 212.5,0 212.5,212.5 0,212.5 0,242.5 212.5,242.5 212.5,455 242.5,455 242.5,242.5   455,242.5 " />
                                                    </svg>
                                                </button>
                                            </div>
                                        </>
                                    )}
                                </div>
                            </div>

                            {/* <div className="sm:grid sm:grid-cols-3 sm:gap-4 sm:items-start sm:border-t sm:border-gray-200 py-5">
                                <label
                                    htmlFor="bundlePrice"
                                    className="block text-sm font-medium text-gray-700 sm:mt-px sm:pt-2"
                                >
                                    {t("form.bundle-price")}
                                </label>
                                <div className="mt-1 sm:mt-0 sm:col-span-2">
                                    <input
                                        required
                                        type="text"
                                        name="bundlePrice"
                                        id="bundlePrice"
                                        value={inputs.bundlePrice || ""}
                                        onChange={handleChange}
                                        className="max-w-lg block w-full shadow-sm focus:ring-black focus:border-black sm:max-w-xs sm:text-sm border-gray-300 p-3"
                                    />
                                </div>
                            </div> */}

                            <div className="sm:grid sm:grid-cols-3 sm:gap-4 sm:items-start sm:border-t sm:border-gray-200 py-5 mt-0">
                                <label
                                    htmlFor="bundleDiscount"
                                    className="block text-sm font-medium text-gray-700 sm:mt-px"
                                >
                                    {t("form.bundle-discount")} <span className="text-lg">*</span>
                                </label>
                                <div className="mt-1 sm:mt-0 sm:col-span-2">
                                    <input
                                        required
                                        type="text"
                                        name="bundleDiscount"
                                        id="bundleDiscount"
                                        value={inputs.bundleDiscount || ""}
                                        onChange={handleChange}
                                        className="max-w-lg block w-full shadow-sm focus:ring-black focus:border-black sm:max-w-xs sm:text-sm border-gray-300 p-3"
                                    />
                                </div>
                            </div>
                        </div>

                        <div className="mb-5">
                            <h3 className="text-lg leading-6 font-medium text-gray-900 pt-5 pt-14">
                                {bundleType} {t("form.duration")}
                            </h3>
                            <p className="mt-1 max-w-2xl text-sm text-gray-500">
                                {t("form.duration-text")}
                            </p>
                        </div>

                        <div className="group bg-gray-100 px-8">
                            <div className="sm:grid sm:grid-cols-3 sm:gap-4 sm:items-start py-5">
                                <label
                                    htmlFor="bundleStartDate"
                                    className="block text-sm font-medium text-gray-700 sm:mt-px sm:pt-2"
                                >
                                    {t("table.start-date")} <span className="text-lg">*</span>
                                </label>
                                <div className="mt-1 sm:mt-0 sm:col-span-2">
                                    <input
                                        required
                                        type="date"
                                        name="bundleStartDate"
                                        id="bundleStartDate"
                                        value={inputs.bundleStartDate || ""}
                                        onChange={handleChange}
                                        className="max-w-lg block w-full shadow-sm focus:ring-black focus:border-black sm:max-w-xs sm:text-sm border-gray-300 p-3"
                                    />
                                </div>
                            </div>

                            <div className="sm:grid sm:grid-cols-3 sm:gap-4 sm:items-start sm:border-t sm:border-gray-200 py-5">
                                <label
                                    htmlFor="bundleEndDate"
                                    className="block text-sm font-medium text-gray-700 sm:mt-px sm:pt-2"
                                >
                                    {t("table.end-date")} <span className="text-lg">*</span>
                                </label>
                                <div className="mt-1 sm:mt-0 sm:col-span-2">
                                    <input
                                        required
                                        type="date"
                                        name="bundleEndDate"
                                        id="bundleEndDate"
                                        value={inputs.bundleEndDate || ""}
                                        onChange={handleChange}
                                        className="max-w-lg block w-full shadow-sm focus:ring-black focus:border-black sm:max-w-xs sm:text-sm border-gray-300 p-3"
                                    />
                                </div>
                            </div>
                        </div>

                        {props.edit ? (
                            <>
                                <div className="mb-5">
                                    <h3 className="text-lg leading-6 font-medium text-gray-900 pt-14">
                                        {t("form.logs")}
                                    </h3>
                                    <p className="mt-1 max-w-2xl text-sm text-gray-500">
                                        {t("form.logs-text")}
                                    </p>
                                </div>

                                <div className="group bg-gray-100 px-8">
                                    <div className="sm:grid sm:grid-cols-3 sm:gap-4 sm:items-start py-5">
                                        <label
                                            htmlFor="bundleEndDate"
                                            className="block text-sm font-medium text-gray-700 sm:mt-px sm:pt-2"
                                        >
                                            {t("table.updated-by")}
                                        </label>
                                        <div className="mt-1 sm:mt-0 sm:col-span-2">
                                            <input
                                                required
                                                disabled
                                                type="text"
                                                value={inputs.bundleUpdatedBy || ""}
                                                onChange={handleChange}
                                                className="max-w-lg block w-full shadow-sm focus:ring-black focus:border-black sm:max-w-xs sm:text-sm border-gray-300 opacity-50 p-3"
                                            />
                                        </div>
                                    </div>
                                    <div className="sm:grid sm:grid-cols-3 sm:gap-4 sm:items-start sm:border-t sm:border-gray-200 py-5">
                                        <label
                                            htmlFor="bundleEndDate"
                                            className="block text-sm font-medium text-gray-700 sm:mt-px sm:pt-2"
                                        >
                                            {t("table.updated-at")}
                                        </label>
                                        <div className="mt-1 sm:mt-0 sm:col-span-2">
                                            <input
                                                required
                                                disabled
                                                type="text"
                                                value={getUpdatedAtTimeString(inputs.bundleUpdatedAt)}
                                                onChange={handleChange}
                                                className="max-w-lg block w-full shadow-sm focus:ring-black focus:border-black sm:max-w-xs sm:text-sm border-gray-300 opacity-50 p-3"
                                            />
                                        </div>
                                    </div>
                                </div>
                            </>
                        ) : null}

                        <div className="mb-5">
                            <h3 className="text-lg leading-6 font-medium text-gray-900 pt-14">
                                {bundleType} {t("form.publication")}
                            </h3>
                            <p className="mt-1 max-w-2xl text-sm text-gray-500">
                                {t("form.publication-text")}
                            </p>
                        </div>

                        <div className="group bg-gray-100 px-8">
                            <div className="sm:grid sm:grid-cols-3 sm:gap-4 sm:items-start py-5">
                                <label
                                    htmlFor="bundlePublished"
                                    className="block text-sm font-medium text-gray-700 sm:mt-px sm:pt-2"
                                >
                                    {t("form.published")}
                                </label>
                                <div className="mt-1 sm:mt-0 sm:col-span-2 sm:pt-2 pt-3">
                                    <label
                                        htmlFor="publish-toggle"
                                        className="inline-flex relative items-center cursor-pointer"
                                    >
                                        <input
                                            onChange={handleChange}
                                            type="checkbox"
                                            name="bundlePublished"
                                            checked={inputs.bundlePublished || false}
                                            id="publish-toggle"
                                            className="sr-only peer"
                                        />
                                        <div className="w-11 h-6 bg-gray-500 peer-focus:outline-none peer-focus:ring-4 peer-focus:ring-gray-200 dark:peer-focus:ring-gray-200 rounded-full peer dark:bg-gray-300 peer-checked:after:translate-x-full peer-checked:after:border-white after:content-[''] after:absolute after:top-[2px] after:left-[2px] after:bg-white after:border-gray-300 after:border after:rounded-full after:h-5 after:w-5 after:transition-all dark:border-gray-600 peer-checked:bg-black"></div>
                                        <span className="ml-3 text-sm text-sm text-gray-500">
                                            {inputs.bundlePublished
                                                ? t("form.published")
                                                : t("form.draft")}
                                        </span>
                                    </label>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                <div className="pt-5">
                    <div className="flex justify-end">
                        <button
                            type="submit"
                            className="ml-3 inline-flex justify-center p-3 border border-transparent shadow-sm text-sm text-white bg-black hover:opacity-50 duration-200 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-black relative">
                            {waitingResponse ? (
                                <div className="absolute animate-spin h-5 w-5 rounded-full border-2 border-white border-l-transparent" />
                            ) : null}
                            <span className={getCTATextClass()}>{getCTAText()} {bundleType}</span>
                        </button>
                    </div>
                </div>
            </form>
        </div>
    ) : (
        <Error404 />
    );
};

const TranslationPopUp = props => {
    const [names, setNames] = useState(props.translations || {})
    const [maxLengthAlert, setMaxLengthAlert] = useState([])
    const { t } = useTranslation("translation");

    const onChange = ({target}) => {
        if(target.value.length === 50){
            setMaxLengthAlert([...maxLengthAlert, target.name.replace("bundleName-","")])
        } else {
            const newMaxLengthLocales = maxLengthAlert.filter(locale => locale !== target.name.replace("bundleName-",""))
            setMaxLengthAlert(newMaxLengthLocales)
        }

        setNames({
            ...names,
            [target.name]: target.value
        })
    }

    const onSave = (event) => {
        event.preventDefault()
        props.saveNames(names)
        props.closePopUp()
    }
    const onClose = (event) => {
        event.preventDefault()
        props.closePopUp()

    }
    return <div className="w-screen h-screen absolute top-0 left-0 bg-black z-50 flex items-center justify-center bg-opacity-60">
        <div className="bg-white py-7 flex flex-col w-5/12">
            <div class="mb-7 px-5">
                <h3 class="text-lg leading-6 font-medium text-gray-900">{t("form.add-bundle-name-translations")}</h3>
            </div>
            <div class="max-h-96 px-5 overflow-y-scroll">
                {props.locales?.map((locale, index) => {
                    const inputId = `bundleName-${locale}`
                    const value = names[inputId]
                    return <div key={index} class="flex justify-between items-center mb-2.5">
                        <label for={inputId} class="block text-sm font-medium text-gray-700 w-16">{locale}</label>
                        <div class="flex grow flex-col ml-5">
                            <input maxLength="50" type="text" onChange={onChange} value={value} id={inputId} name={inputId} placeholder="Bundle Name" class="flex-1 block grow focus:ring-black focus:border-black min-w-0  sm:text-sm border-gray-300 p-3 false" />
                            {maxLengthAlert.includes(locale) && <span class="text-red-600 text-xs mt-1.5">{t("form.bundle-name-max-length")}</span>}
                        </div>
                    </div>
                })}
            </div>
            <div class="flex mt-7 px-5">
                <button
                    onClick={onClose}
                    className="grow justify-center border border-gray-300 shadow-sm p-3 bg-white text-base font-medium text-gray-700 hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-black sm:mt-0 sm:col-start-1 sm:text-sm mx-1 hover:opacity-75 text-center duration-200"
                >
                    {t('form.alert.close')}
                </button>
                <button
                    onClick={onSave}
                    type="submit"
                    className="grow justify-center border border-transparent shadow-sm p-3 bg-black text-base font-medium text-white hover:bg-black focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-black sm:col-start-2 sm:text-sm mx-1 hover:opacity-75 text-center duration-200"
                >
                    {t('form.alert.save')}
                </button>
            </div>
        </div>
    </div>
}


export default BundleForm;


// const JSONDevTool = data => {
//     return <div class="absolute shadow top-5 right-5 w-92 bg-white p-5" style={{zIndex: 100, fontSize: 10}}>
//         <pre>
//             <code>
//                 {JSON.stringify(data, null, '  ')}
//             </code>
//         </pre>
//     </div>
// }