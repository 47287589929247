import React, { useState, useEffect } from "react";
import { useTranslation } from "react-i18next";
import FileUploader from "../../components/FileUploader";
import "../../i18n";
import { validateToken } from "@/utils/admin/fetch";
import { handleLogout } from "@/utils/admin/logout";
import { importCrossBundlesFromFile } from "@/utils/admin/fetch";
import Alert from "@/components/admin/Alert";


const BundleImport = () => {
  const { t } = useTranslation("translation");
  const [selectedFile, setSelectedFile] = useState(null);

  const [alert, setAlert] = useState(null);

  const alertCloseAction = {
    callback: () => setAlert(null),
    title: t("form.alert.close"),
  }

  useEffect(() => {
    validateToken()
      .then(res => {
        if (!res) handleLogout()
      })
  }, [])

  const submitForm = (event) => {
    event.preventDefault();
    const formData = new FormData();
    formData.append('importBundles', selectedFile)
    importCrossBundlesFromFile(formData)
      .then(res => {
        if (res.status === 401) handleLogout()
        else if (res.status === 200 && !res.invalid?.length) setAlert({
          shown: true,
          error: false,
          title: t("form.import-success"),
          message:  `${t('form.import-success-bundles')}: ${res.success.join(', ')}`,
          actions: {
            primary: {
              callback: () => (window.location.href = "/list/cross-locale"),
              title: t("form.back-to-list"),
            },
          },
        });
        else if (res.status === 200 && res.invalid?.length) setAlert({
          shown: true,
          error: true,
          title: t("form.import-error"),
          message: `${t('form.import-error-message')}
          
          ${t('form.import-invalid-bundles')}: ${res.invalid.join(', ')}
          ${t('form.import-success-bundles')}: ${res.success.join(', ')}`,
          actions: {
            primary: alertCloseAction
          },
        })

        else setAlert('Error')
      })
  }

  return (
    <div className="page-content">
      <Alert
        title={alert?.title}
        message={alert?.message}
        error={alert?.error}
        shown={alert?.shown}
        actions={alert?.actions}
      />

      <form className="container mx-auto">
        <div className="mb-12">
          <h3 className="text-lg leading-6 font-medium text-gray-900">
            {t("form.import-file")}
          </h3>
          <p className="mt-1 max-w-2xl text-sm text-gray-500">
            <div dangerouslySetInnerHTML={{ __html: t("form.file-intro-text") }}></div>
          </p>
        </div>
        <div className="mb-12">
          <FileUploader
            onFileSelectSuccess={(file) => setSelectedFile(file)}
            onFileSelectError={(error) => setAlert({...error, actions: {
              primary: alertCloseAction
            }})}
          />
          <button className={`inline-flex justify-center p-3 border border-transparent shadow-sm text-sm font-medium text-white bg-black hover:opacity-50 duration-200 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-black relative disabled:opacity-50`} onClick={submitForm} disabled={!selectedFile}>
            {t("form.import-submit")}
          </button>
        </div>

        {/* text button with tailwind */}

        <div className="my-14 flex sm:flex-row flex-col">
          <a href="/import_example.xlsx" className="btn px-3.5 py-2.5 bg-white font-semibold text-sm bg-gray-100 hover:opacity-50 duration-200">
            {t("form.import-download-example")}
          </a>
          <a href="/Instructions.xls" className="sm:ml-1.5 mt-1.5 sm:mt-0 btn px-3.5 py-2.5 bg-white font-semibold text-sm bg-gray-100 hover:opacity-50 duration-200">
            {t("form.import-download-instructions")}
          </a>
        </div>
      </form>
    </div>
  );
};

export default BundleImport;
